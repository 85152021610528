// Main imports
import { Routes, Route, Navigate } from "react-router-dom";

// Views and components
import Brands from "./views/Brands/Brands";
import Footer from "./components/ui/Footer/Footer";
import Home from "./views/Home/Home";

// Data and context
import restaurantData from "./data/restaurantData.json";
import WebplaceProvider from "./store/webplace-provider";

// Utilities
import "./App.css";

// GET INDEX OF NAVBAR OBJECT
const findObject = (arr, objName) => {
  const object = arr.findIndex((item) => item.title === objName);
  return object;
};

const navbarData = restaurantData.navbar_items.options;

const App = () => {
  return (
    <WebplaceProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Show and redirect to Brands */}
        {navbarData[findObject(navbarData, "Brands")].shown && (
          <Route
            path={
              restaurantData.navbar_items.options[
                findObject(restaurantData.navbar_items.options, "Brands")
              ].url
            }
            element={<Brands />}
          />
        )}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </WebplaceProvider>
  );
};

export default App;
